@import "keep-react/css";
 

body {
  cursor: url('../public/img/cursor.png'), auto;

}

 

a, button, .upload, img.PostulacionItemImagen, button {
	cursor: url('../public/img/pointer.png'), auto;
  }


  

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
background: rgb(0,0,0);
background: linear-gradient(97deg, rgba(233, 217, 252, 1) 0%, rgba(240, 0, 12, 1) 25%, rgba(239,68,68,0.6564399441417192) 31%, rgba(255, 246, 230, 1) 65%, rgba(233, 217, 252, 1) 90%);
border-radius: 5px;

}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: pink; 
  border-radius: 5px;
}


.salto{
  display: none;
}

.font-roboto{
  font-family: 'Roboto Mono', monospace;
}

h2.text-black.font-black.text-5xl.relative.top-0.right-0 {
  float: right;
}

hr {
  display: none;
  border-color: #fff6e6;
}



.zona_foto {
  min-width: 175px;
}


.zona_foto  > img{
  width: 173px;
}



.zona_fotoActual {
  min-width: 195px;
}


.zona_fotoActual  > img{
  width: 195px;
}

.ganadora_actual{
  margin-top: 4.5rem;
  text-align: left;
}

header{
  background-color: #FFF6E6;
}

#section_1{
  background-color: #E9D9FC;
		margin-top: -4px;
		display: flex;
		flex-direction: row;
		width:100%;
}

#section_ganadoras{
  background-color: #fff6e6;
		margin-top: -4px;
		display: flex;
		flex-direction: row;
		width:100%;
}



#section_votacion{
    background-color: #E9D9FC;
		margin-top: -4px;
		display: flex;
		flex-direction: row;
		width:100%;
    padding: 4%;
    padding-top: 1%;
}




#faq{
  background-color: #E9D9FC;
}

.presentan{
  float: left;
  width: 40%;
  padding-left: 8%;
  background-color: #E9D9FC;
  display: flex;
  justify-content: center;
  padding-top: 56px;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: 4rem;
}

  .presentan > img {
    margin-bottom: 1.5rem;
    margin-right: 3rem;
    width: 54%;
  }


  .presentanGanadoras {
    float: left;
    width: 34%;
    padding-left: 8%;
    background-color: #fff6e6;
    display: flex;
    justify-content: center;
    padding-top: 4.49rem;
    align-self: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 4rem;
    font-family: 'Roboto Mono', monospace;
}

.icons-header {
  padding-top: 12%;
  float: left;
  width: 10%;
  background-color: #E9D9FC;
  text-align: left;
}
.icons-header > div {
  padding: 12px 0px 12px 78px;
}



.icons-header-ganadoras {
  padding-top: 12%;
  float: left;
  width: 10%;
  background-color: #fff6e6;
  text-align: left;
}
.icons-header-ganadoras > div {
  padding: 12px 0px 12px 78px;
}

.tecnica{
    font-family: 'Inter', sans-serif;
    color: #000;
    font-size: 16px;
    min-width: 100% !important;
    font-weight: 100;
    padding-top: 1rem;

}

.icons__img{
  max-height: 27px;
}

.contenido{
  padding-top: 56px;
  float: left;
  width: 50%;
  background-color:#E9D9FC;
  text-align: left;
}

h1 {
  color: #F0000C;
  font-size: 2.5rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.titulo_principal{
	color: #F0000C;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    margin-top: 1rem;
    margin-bottom: 2rem;
}


.titulo_principal_votacion{
	color: #F0000C;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}






.contenido__titulo {
	color: #F0000C;
    font-size: 50px;
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    margin-top: 1rem;
    margin-bottom: 1rem;
	font-size: 16px;
}

.contenido__postulado {
	color: #F0000C;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 58px;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: 30px;
    max-width: 219px !important;
}

.presentan__titulo {
	color: #F0000C;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: 58px;
    margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 100;
}




.contenidoGanadoras{
  padding-top: 73px;
  float: left;
  width: 56%;
  background-color:#fff6e6;
  text-align: left;
}


.contenido__tituloGanadoras {
  color: #2a2425;
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  line-height: 16PX;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 14px;
}



.contenido__tituloGanadorasActual {
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  font-size: 19px;
  background-color: #F0000C;
  color: #E9D9FC;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;
}

.titulo_principalGanadoras{
  color: #2f2727;
  font-size: 1.3rem;
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
  line-height: 1.4rem;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}


.titulo_principalGanadorasActual{
  color: #F0000C;
  font-size: 3.1rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 58px;
  margin-top: 2rem;
  margin-bottom: 3rem;
  text-decoration: underline;
  text-underline-offset: 15px;
  text-decoration-thickness: 5px;
}

.contenidoGanadorasActual{
  max-width: 85% !important;
  text-align: left;
}


.contenido__postuladoGanadoras {
	color: #F0000C;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 58px;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: 30px;
    max-width: 219px !important;
}

.presentan__tituloGanadoras {
	color: #F0000C;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: 58px;
    margin-bottom: 1rem;
	font-size: 16px;
	font-weight: 100;
}

.fotoObra{
  width: 100%;
  max-width: 65%;
}

.graficoPrincipal {
    min-height: calc(23vw);
    max-height: calc(23vw);
    text-align: center;
    background-color: white;
}

.PostulacionContenedor{
	width: 25%;
    float: left;
}

.PostulacionItem{
	background-color: #ffffff;
  width: 100%;
  min-height: 720px !important;
  padding-left: 2%;
  padding-right: 2%;
	}	

	.PostulacionItem:hover{
    -webkit-box-shadow: 0px 0px 46px -4px rgb(103 58 183);
    -moz-box-shadow: 0px 0px 46px -4px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 46px -4px rgb(75 10 120 / 75%);
	}
	
	.PostulacionItem > table{
		width: 100%;
		padding-left: 5%;
		padding-right: 5%;
	}



	.PostulacionItem > table > tbody > tr > td:nth-child(2) > a {
		text-align: center;
    font-size: calc(1.1em);
    font-family: "akzidenz-grotesk",sans-serif;
    /* text-align: left; */
    /* text-decoration: none; */
    color: white;
    background-color: black;
    border-radius: calc(1.1em);
    /* padding: 4px; */
    display: inline-block;
    margin-bottom: calc(1vw);
    cursor: pointer;
    padding-left: calc(0.5em);
    padding-right: calc(0.5em);
    padding-top: calc(0.4vw);
    padding-bottom: calc(0.4vw);
    margin-top: 0px;
    margin-bottom: 0px;
	}


	.PostulacionItem > table > tbody > tr > td:nth-child(1) > h1{
	font-size: calc(1em);
    text-align: left;
    margin-left: calc(1em);
    margin-bottom: 0px !important;
    margin-top: 0px;
    line-height: calc(1em);
    color: #000000;
    letter-spacing: 0;
	}


.PostulacionItem > table > tbody > tr > td:nth-child(2) > a:hover {
    color: white;
    background-color: red;
	font-family:"akzidenz-grotesk",sans-serif;

}

	.PostulacionItem > table > tbody > tr > td:nth-child(2) {
		text-align: right !important;
	}
	
	.PostulacionItem >  table > tbody > tr > td:nth-child(1) > h1 {
	font-size: calc(1em);
    text-align: left;
    margin-left: calc(1vw);
    margin-bottom: 0px !important;
    margin-top: 0px;
    line-height: calc(1em);
    color: #000000;
    letter-spacing: 0;
	}	

	.PostulacionItem >  table > tbody > tr > td:nth-child(1) > {
    text-align: center;
	}	

 	

	.ImgPrincipal{
		max-width: 90%;
		max-height: calc(19.9vw);
		cursor:pointer;
		margin-bottom: 15px;
	}

		
.contenedorPostulacion{
	width: 33.3%;
	float: left;
}
		

.PostulacionItem > h1 {
	font-size: calc(1.2em);
    text-align: left;
    margin-left: calc(1vw);
    margin-bottom: 0px !important;
    margin-top: 5px;
    line-height: calc(1.2em);
    color: #000000;
    margin-left: 5%;
    letter-spacing: 0;
	padding-bottom: 3%;
}	
		
		td {
    text-align: center;
}
		
		
		
 
.PostulacionItem > h2  {
	letter-spacing: 0;
    margin-left: 5%;
    color: #0f0f0f;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    font-size: calc(2vw);
    font-family: 'Akzidenz Grotesk BE Bold';
    text-align: left;
}
		
 
.PostulacionItem > h3 {
    margin-right: 5%;
    color: #0f0f0f;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    font-size: calc(1.1em);
    font-family: 'Akzidenz Grotesk BE Bold';
    text-align: left;
    font-family: 'Inter', sans-serif;
    padding-bottom: 0px;
    font-style: normal;
    font-weight: 500;
    line-height: calc(1.1em);
}	
		 

.btn_botar{
    text-align: center;
    font-size: calc(0.7em);
    font-size: 15px;
    font-family: "akzidenz-grotesk", sans-serif;
    text-align: center;
    text-decoration: none;
    color: white;
    background-color: black;
    border-radius: 12px;
    display: inline-block;
    margin-bottom: calc(1vw);
    padding-left: calc(0.5em);
    padding-right: calc(0.5em);
    padding-top: calc(0.4vw);
    padding-bottom: calc(0.4vw);
    margin-bottom: 0px;
}

.subtituloVotar{
  color: #F0000C;
  /* font-size: 50px; */
  font-family: 'Inter', sans-serif;
  text-align: left;
  font-size: 0.95rem;
  letter-spacing: 0.009rem;
  margin-top: 2%;
  line-height: 16px !important;
  margin-top: 1%;
  margin-bottom: 0px;
  font-weight: 700;
}


.tituloVotar{
  color: #F0000C;
  text-align: left;
  font-size: 1.9rem;
  margin-top: 2%;
  font-family: 'Inter', sans-serif;
  margin-top: 1%;
  margin-bottom: 0px;
  font-weight: 700;
  margin-bottom: 5%;
}


.contenedorVotacion{
  padding-left: 2%;
  padding-right: 2%;
}

.instrucciones{
margin-bottom: 4%;
}

.instrucciones > li {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  min-width: 100% !important;
  padding-left: 2%;
  padding-right: 2%;
  font-weight: 500;
  color: #F0000C;
  display: inline-block;
}




 



#faq_titulo{
	color: #F0000C;
    font-family: Inter, sans-serif;
    font-weight: 600;
	font-size: 2.25rem;
	line-height: 2.5rem;
}

.faq_item_titulo{
	font-size: 1.5rem;
	line-height: 2rem;
	font-family: Inter, sans-serif;
    font-weight: 600;
}

h2 {
	color: #F0030D;
	margin-bottom: 0px;
	text-align: left;
	font-family: 'Roboto Mono', monospace;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

h2.contenido__subtitulo {
    color: #F0030D;
    margin-bottom: 0px;
    text-align: left;
    font-family: 'Roboto Mono', monospace;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#premios_titulo{
	color: #F0030D;
	margin-bottom: 0px;
	text-align: left;
	font-family: 'Roboto Mono', monospace;
	font-size: 17px !important;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin-bottom: 1rem;
	margin-bottom: 1rem;
}

.texto__principal {
  padding: 0px 0px 31px 0px;
}

.texto__principal > p {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}

p {
  font-family: 'Inter', sans-serif;
  color: #F0000C;
  font-size: 18px;
}

 

.premio__medium {
  font-weight:500;
}
.premio__semibold {
  font-weight:600;
}
.postular-btns {
  margin-top: 65px;
    margin-bottom: 60px;
}
.postular-btns > a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: solid 2px #F0000C;
  cursor:pointer;
}


.ganadoras-btns {
  margin-top: 0px;
  margin-bottom: 113px;
}

.ganadoras-btns > a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: solid 2px #F0000C;
  cursor:pointer;
}

.btn__ganadoras {
  color: #F0000C;
  background-color: #E9D9FC;
  padding: 17px 28px 17px 28px;
}
.btn__ganadoras:hover {
  background-color: #D7040E;
  color: #E9D9FC;
  border-color:#D7040E;
}



.ganadoras-btns2 {
margin-top: 4rem;
}

.ganadoras-btns2 > a {
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  color: #F0000C;
  padding: 17px 28px 17px 28px;
  border: 2px solid red;
}

.btn__ganadoras2 {
}
.btn__ganadoras2:hover {
  background-color: #D7040E;
  color: #E9D9FC;
}

.ganadoras-btns2Movil{
  padding: 5px;
}

.btn__ganadoras2Movil {
  display: none;
  margin-bottom: 3rem;
  text-align: center;
}
.btn__ganadoras2Movil:hover {
  background-color: #D7040E;
  color: #E9D9FC;
}

#section_1 > div > div.contenido > div > div.postular-btns > a:nth-child(2){
	margin-left: 1rem  !important;
}



.btn__postula {
  background-color: #F0000C;
  color: #E9D9FC;
  margin-right: 29px;
  padding: 17px 28px 17px 28px;
  text-align: center;
}
.btn__bases {
  color: #F0000C;
  background-color: #E9D9FC;
  padding: 17px 28px 17px 28px;
}
.btn__postula:hover {
  background-color: #D7040E;
  border-color:#D7040E;
}
.btn__bases:hover {
  background-color: #D7040E;
  color: #E9D9FC;
  border-color:#D7040E;
}

.ganadoras__text {
  display:none;
}

.faq-container {
  padding: 61px 37px 51px 37px;
  width:auto;
}


#section_2{
  background-color: #FFF6E6;
}



footer {
  display: block;
  unicode-bidi: isolate;
  background-color: #FFF6E6;

}

 

#IMGLogoFooterDesk {
	max-width:310px;
	display: block;
}
#IMGLogoFooterMovil {
	display: none;
}

#Footer {
  background-color: #FFF6E6;
  display: flex;
  /* flex-direction: row; */
  /* flex-wrap: wrap; */
  padding: 59px 80px 89px 114px;
  text-align: left;
  justify-content: space-between;
}

#Derecha {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 190px 72px 16px 220px;
}

#Derecha > a {
  text-decoration: none;
}

.derecha__bold {
	font-weight: 600;
	color: #F0000C;
	font-family: 'Inter', sans-serif;
	font-size: 34px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}

#Derecha > div.icons-footer > a {
  margin-right: 15px;
}

h4 {
  font-family: 'Inter', sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#titulo_postulacion{
	color: #F0000C;
    font-size: 2.5rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 58px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#subtitulo_postulacion{
	color: #F0000C;
    font-size: 50px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-size: 16px;
	margin-top: 0.25rem !important;
    margin-bottom: 3rem !important;
}
.derecha__regular {
	font-weight: 400;
	color:#F0000C;
	color: #f0000c;
	font-size: 34px;
	font-style: normal;
	line-height: normal;
}


#IMGGanadoras-mobile{
display:none;
}

#LinkVotaMobile, .media-kit-mobile, .izquierda__texts-mobile {
  display: none;
}

.icons-footer {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 10px;
	margin-top: 2rem;
}
  
.media-kit {
  display: flex;
  align-items: baseline;
  padding-top: 20px;
}

#LinkVotaMobile, .media-kit-mobile, .izquierda__texts-mobile {
  display: none;
}


.text-cream{
  color:#F0000C;
  color: #F0000C;
  font-family: Inter, sans-serif;
  font-weight: 600;
}

#HeaderIMG-mobile{
  display:none;
}


.container_logo_movil{
	display: none;
}

#LOGOS_MOVIL{
	display: none;
}

 
label {
    color: #f0000c;
    margin-bottom: 0px;
    font-family: "Roboto Mono", monospace;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 9px;
}

.frow {
    width: 100%;
	display: flex;;
}

.frow > .c1 {
    width: 100%;
}



.frow > .c2 > div, .frow > .c1 > div{
	color: #5b0e0e;
    width: 100%;
    display: inline-block;
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}


.frow > .c1 > input {
	padding: 0px 10px;
}

.ErrorMessage{
	min-height: 1rem;
}
 
.frow > .c1 > textarea {
    width: 100%;
    height: 168px;
    border: none;
    background: rgba(240, 0, 12, 0.12);
    color: #f0000c;
    font-family: "Roboto Mono", monospace;
	padding: 10px 10px;
}

.frow > .c1 > span {
    visibility: hidden;
    display: inline-block;
}


.frow > .c1 > input {
    width: 100%;
    height: 35px;
    border: none;
    background: rgba(240, 0, 12, 0.12);
    color: #f0000c;
    font-family: "Roboto Mono", monospace;
}


 

.frow > .c2 {
    float: left;
	width: 50%;
}

.frow > .c2:first-child {
    padding-right: 20px;
}

.frow > .c2 > input, .frow > .c2 > select {
    padding: 0px 10px;
}

.frow > .c2 > span {
    visibility: hidden;
}

.frow > .c2 > input, .frow > .c2 > select {
    width: 100%;
    height: 35px;
    border: none;
    background: rgba(240, 0, 12, 0.12);
    color: #f0000c;
    font-family: "Roboto Mono", monospace;
}


.titulo__item_faq{
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 26px;	
	padding: 12px;
	color: #f0000c;
}


.texto__item_faq{
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;	
	padding: 12px;
}
 


.frow > .c2 > label {
    width: 100%;
    display: inline-block;
}


.frow > .c2 > label {
    width: 100%;
    display: inline-block;
	color: #f0000c;
    margin-bottom: 0px;
    font-family: "Roboto Mono", monospace;
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 9px;
}


button[type="submit"] {
    background-color: #f0000c;
    color: #e9d9fc;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    cursor: pointer;
    width: 213px;
    height: 59px;
	margin-bottom: 3rem;
}

input[type="submit"]:hover {
    color: #f0000c;
    background-color: #fff6e674;
    border: solid 1px #f0000c;
}


.titulo_ficha {
    font-family: 'Inter', sans-serif;
    color: #F0000C;
    font-size: 20px;
    font-weight: 700;
}



@media (max-width: 1300px) {


  .Derecha{
  display: none;
  }
  
  .presentanGanadoras{
    width: 100%;
    padding-left: 0%;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .contenidoGanadoras {
    padding-top: 1rem;
    width: 100%;
  }

  .icons-header-ganadoras {
    display: none;
  }
  

 
  
  .ganadoras_anos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    width: 80%; /* Ajusta el ancho del contenedor si es necesario */
    margin: 0 auto;
  }
  
  .ganadoras_anos li {
    width: calc(25% - 10px);
    margin: 5px;
  }
  

}



@media (max-width: 768px) {


  .btn__ganadoras2Movil {
    display: block;
  }
  .btn__ganadoras2Movil:hover {
    background-color: #D7040E;
    color: #E9D9FC;
  }

  .contenidoGanadorasActual{
    max-width: 100% !important;
    text-align: center;
  }

  .titulo_principalGanadorasActual {
    color: #F0000C;
    font-size: 2.8rem;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 5px;
}

.salto{
  display: block;
}

  .ganadora_actual{
    padding: 0px 31px 0px 31px;
    margin-top: 0rem;
    text-align: center;
    margin-bottom: 2rem;
  }
 
  .contenidoGanadoras > .ganadora {
    padding-bottom: 0rem !important;
    max-width: 100% !important;
    margin-bottom: 4rem;
  }

  hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: none;
  }

.contenidoGanadoras > .ganadora:first-child > div > div > div > hr {
  display: none !important;
}
  
  .fotoObra{
    width: 100%;
    max-width: 100% !important;
  }

  .presentanGanadoras{
    width: 100%;
    padding-left: 0%;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }

  .contenidoGanadoras {
    padding-top: 1rem;
    width: 100%;
  }

  .icons-header-ganadoras {
    display: none;
  }
  
  .contenido__tituloGanadoras {
    margin-top: 1.1rem;
    text-align: center;
  }
  
  .titulo_principalGanadoras{
    text-align: center;
  }

.zona_foto{
  width: 100%;
}

.zona_foto > img {
  width: 40%;
  margin: auto;
}

.zona_fotoActual{
  width: 100%;
}

.zona_fotoActual > img {
  width: 45%;
  margin: auto;
}


#section_ganadoras{
  padding:1.3rem;
}
   
  .ganadoras_anos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 0rem !important;
    margin-top: 0px !important;
    font-size: 1.5rem !important;
    line-height: 1.0rem !important;
  }
  
  .ganadoras_anos li {
    width: calc(25% - 10px);
    margin: 5px;
    text-align: center;
  }
















	.frow {
		width: 100%;
		display: block;
	}

	.frow > .c2{
		width: 100%;
	}

	.frow > .c2:first-child {
		padding-right: 0px;
	}

	.FPAM{
		padding: 0px 26px;
	}

	#LOGOS_MOVIL{
		display: block;
	}

	.container_logo_movil{
		background-color: #F0000C;
		display: block;
	}

	#HeaderIMG, #IMGGanadoras, .HeaderIMG {
		display:none;
	}
	#HeaderIMG-mobile {
		display: block;
    	width: 100%;
	}
	#IMGGanadoras-mobile {
		display:flex;
	}

	.presentan{
		display: none;
	}

	.container {
		padding:0;
	}

	.contenido{
		padding-top: 15px;
		width: 100%;
		width: 100%;
	}
	.contenido > h1 {
		padding: 0px 26px 18px;
	}
	.icons-header {
		display: none
	}

  .icons-header-ganadoras {
		display: none
	}

	h1 {
		font-size: 30px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px;
	}

	.titulo_principal{
    font-size: 41px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
	}

  
	.titulo_principal_votacion{
		font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
	}



	body > header > div:nth-child(3) > div:nth-child(2) > p {
		font-size: calc(3.6vw);
		letter-spacing: calc(0.01vw);
		line-height: calc(4.3vw);
		font-family: Helvetica, Sans-Serif;
		font-weight: bold !important;
		margin-left: 5%;
		margin-right: 5%;
		padding: 0%;
	}

	.contenido__titulo {
		font-size: 16px;
		padding: 0px 26px;
	}

	.contenido__subtitulo {
		padding: 0px 0px 0px 26px;
	}

	.texto__principal {
    max-width: 100%;
    padding: 0px 31px 20px 31px;
    text-align: start;
    line-height: 22px;
	}
	.texto__principal > p {
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;
	}

	.texto__item_faq{
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 26px;	
		padding: 12px;
	}

	.texto__premios > p, .texto__premios > h2, .premio__container {
		margin-left: 34px;
	}

	.texto__premios > h2{
		margin-top: 1rem;
		margin-bottom: 1rem;
		font-weight: 800;
	}

	.postular-btns {
		display: flex;
        flex-direction: column;
        align-items: center;
		margin-top: 40px;
		margin-bottom: 35px;
	}

  .postular-btns > a {
		margin-top: 35px;
		max-width: 210px;
    	text-align: center;
	}

  .ganadoras-btns {
		display: flex;
        flex-direction: column;
        align-items: center;
		margin-top: 0px;
		margin-bottom: 70px;
	}

  
  .ganadoras-btns > a {
		margin-top: 0px !important;
		max-width: 210px;
    text-align: center;
    margin-left: 1rem !important;
	}



	.ganadoras__text {
		display:none;
	}

	.faq-container {
		padding: 61px 37px 51px 37px;
		width:auto;
	}

	#Footer {
		flex-direction:column;
		align-content: center;
		padding: 54px 31px;
	}
	#IMGLogoFooterDesk {
		display: none;
	}
	#IMGLogoFooterMovil {
		max-width:80%;
		display: block;
	}
	.izquierda__texts, .media-kit {
		display:none;
	}
	.izquierda__texts-mobile{
		display: flex;
		flex-direction: column;
	}
	.copyright {
		font-size: 16px;
		padding-bottom: 24px;
		padding-top: 12px;
	}
	.icons-footer {
		padding-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
	.icon__footer {
		max-width: 33px;
	}
	.hashtag__footer {
		max-width: 127px;
	}

	.media-kit-mobile {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}
	.detail__container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

	}
	.detail__container > a {
		text-decoration: none;
		padding-right: 20px;
	}
	.media-kit-mobile > a {
		text-decoration: none;
	}
	.media-kit-mobile > a >  h4 {
		font-size: 20px;
		padding-left: 20px;
	}

	#Derecha {
    padding: 7px;
	margin-top: 3rem;
	}

	#Derecha > a > h4 {
		font-size: 22px;
	}
	.derecha__bold {
		padding: 0;
	}
	.derecha__regular {
		padding:0px 0px 24px 0px;
	}

 
	.container-convocatoria-mobile {
		padding: 25px 15px 0px 15px;
	}

	#LinkVotaMobile {
		/* display: flex;
		max-width: 342px; */
		width: 100%;
		display: block;
	}

	.convocatoria-mobile{
		display:flex;
		flex-direction: column;
	}
	.logos-mobile-container {
		background-color: #F0000C;
		padding: 28px 0px 60px 28px;
	}
	.convocatoria-mobile > h3 {
		font-family: 'Inter', sans-serif;
		color: #F0000C;
		font-size:26px;
		text-align:left;
		padding: 10px 0px 10px 28px;
	}

	.convocatoria-mobile > img {
		max-width: 360px;
	}

	#VotarCerrar2{
		margin-right: 13%;
	}

	.arrow {
		display:none
	}

}

.PostulacionItemImagen{
  cursor: url('../public/img/pointer.png'), auto; 
}

.ganadoras_anos {
  color: #F0000C;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1rem;
  font-size:  2.1rem;
  line-height: 2.1rem;
}

.zona_foto{
  min-width: 175px;
}

.contenidoGanadoras > .ganadora {
  padding-bottom: 3.5rem; /* Equivalente a pt-14 en Tailwind */
  max-width: 82%;
}

.bg_yellow {
  background-color: #fff6e6 !important;
}

.bg_purple {
  background-color: #e9d9fc !important;
  color: #000000;
}

.text-caracteristicas{
  font-size: 15px;

}


#BtnEnviarVoto {
  background-color: #f0000c;
  color: #e9d9fc;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  width: 213px;
  height: 59px;
  margin-bottom: 3rem;
  background-color: #e9d9fc !important;
  color: #000000;
}



.loading_full{
  background-color: #f4c2f783;
  display: flex;
  justify-content: center;  /* Centra horizontalmente */
  align-items: center;      /* Centra verticalmente */
  position: fixed;          /* Para que el spinner esté fijo en la pantalla */
  top: 0;
  left: 0;
  width: 100vw;             /* Ocupa todo el ancho de la pantalla */
  height: 100vh;            /* Ocupa todo el alto de la pantalla */
  background-color: rgba(255, 255, 255, 0.8);  /* Fondo semitransparente opcional */
  z-index: 1000;            /* Asegúrate de que esté sobre otros elementos */
}



@media (max-width: 768px) {
  .contenido__tituloGanadorasActual{
    margin: auto;
    margin-top: 2rem;
    font-size: 14px;
  }
}





@media (max-width: 1130px) {
  #Derecha {
    display: none !important;
}

}